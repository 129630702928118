@import "main.css";
.bandeau {
display : none !important;
}

h1 {
    font-family: 'Monaco';
}

.display-3 {
color : #6F5207 !important;
}

.nav-link{
color : #6F5207 ! important;
}

